<template>
	<div id="pricing">
		<div id="plans">
			<h2>Pricing</h2>
			<br />

			<div v-if="$route.name === 'Pricing'">
				<Offer />
				<br />
				<br />
				<br />
				<h2 style="text-align: center">
					When you have chosen one of the three routes. I am offering
					you three options with great value.
				</h2>

				<br />
				<br />
				<br />
				<br />
			</div>
			<div class="row">
				<div class="col-md-4 col-sm-6 col-xs-12 text-center">
					<h2>
						<strong class="overwrite-strong-h2"
							>Basic Artwork</strong
						>
					</h2>
					<h2><strong>390€</strong></h2>
					<h3>(express 48h delivery 790€)</h3>
					<ul>
						<li>
							<p>
								One additional round of tweaking of the chosen
								logo to your specs, to be more specific and
								ownable (as an email from you or a 30-minute
								phone call).
							</p>
						</li>
						<li>
							<p>
								The logo versions of your chosen route as vector
								and .png files on transparent background.
							</p>
						</li>
						<li>
							<p>The HEX# of your colour palette.</p>
						</li>
						<li>
							<p>
								The direct link to the source file on Canva, so
								that you or your designer can expand your visual
								design to any touchpoints you desire.
							</p>
						</li>
					</ul>
				</div>

				<div class="col-md-4 col-sm-6 col-xs-12 text-center">
					<h2>
						<strong class="overwrite-strong-h2"
							>Launch ready</strong
						>
					</h2>
					<h2><strong>1 990€</strong></h2>
					<h3>(express delivery in 5 working days 3 990€)</h3>
					<ul>
						<li>
							<p>
								✔️ Everything from
								<strong>Basic Artwork</strong>
							</p>
						</li>
						<li>
							<p>
								An 8.5 x 5.5cm business card design, back and
								front as a fillable Canva template
							</p>
						</li>
						<li>
							<p>
								A4 brochure cover layout or a web landing page
								layout as a Canva template
							</p>
						</li>
						<li>
							<p>
								One social media template (FB, Twitter or
								Instagram of your choice) as a Canva template
							</p>
						</li>
						<li>
							<p>
								One additional touchpoint of your choice
								(rollup, crew shirt, PPT template..)
							</p>
						</li>
					</ul>
				</div>
				<div class="col-md-4 col-sm-6 col-xs-12 text-center">
					<h2>
						<strong class="overwrite-strong-h2">Full house</strong>
					</h2>
					<h2><strong>6 990€</strong></h2>
					<h3>(express delivery in 10 working days 12 990€)</h3>
					<ul>
						<li>
							<p>
								✔️ Everything from
								<strong>Launch ready</strong>
							</p>
						</li>
						<li>
							<p>
								Two touchpoints of your choice (rollup, crew
								shirt, PPT template..)
							</p>
						</li>
						<li>
							<p>Brand guidelines (6-10 pages)</p>
						</li>
						<li>
							<p>
								Three social media templates (FB, Twitter and
								Instagram) as Canva templates
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Offer from '../components/Home/Offer';

	export default {
		name: 'Pricing',
		mounted() {
			window.location = 'https://custombranding.pro';
		},
		components: {
			Offer
		}
	};
</script>

<style scoped>
	#pricing {
		margin: 0 auto;
		margin-top: 10vh;
		max-width: 90%;
		padding: 10px;
	}

	#plans {
		padding-top: 50px;
		padding-bottom: 50px;
		overflow-x: hidden;
		width: 100%;
		max-width: 100%;
	}

	h2 {
		text-align: center;
		color: var(--accent);
		font-size: 3rem;
		margin: 0;
	}

	#plans h2 {
		color: var(--black);
		font-size: 1.5rem;
		padding-top: 5px;
	}

	#plans h2 strong {
		color: var(--accent);
	}

	#plans h2 .overwrite-strong-h2 {
		color: var(--black) !important;
	}

	#plans h3 strong {
		color: var(--accent);
		white-space: nowrap;
	}

	#plans div {
		text-align: center;
	}

	#plans ul {
		padding-top: 10px;
	}

	#plans p {
		text-align: left;
		font-size: 1rem;
	}

	#plans img {
		height: 2rem;
	}
</style>
