<template>
	<div>
		<div id="content" class="row">
			<div class="col-xs-12">
				<img
					src="../../assets/main-logo.png"
					alt="Custom Branding PRO"
				/>
			</div>
			<div class="col-xs-12">
				<h3>My offer to you is</h3>
			</div>
			<div class="col-xs-12">
				<h4>A custom-made brand kit for 297€ within *48 hours.</h4>
			</div>
			<div class="col-xs-12">
				<p>
					(You will actually get not one but
					<strong>three</strong>
					options!)
				</p>
			</div>
			<div class="col-xs-12">
				<router-link class="button" to="get-started" target="_blank">
					<button>Sign up</button>
				</router-link>
			</div>
			<div class="col-xs-12">
				<em style="font-size: 0.5rem; color: var(--white)"
					>*48h during working days Mon-Fri</em
				>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Offer'
	};
</script>

<style scoped>
	* {
		overflow-x: hidden;
	}

	div {
		background-color: var(--accent);
		text-align: center;
		display: flex;
		align-items: center;
		width: 100%;
	}

	#content {
		max-width: 80%;
		margin: 0 auto;
		text-align: center;
		padding: 10px;
	}

	#content * {
		width: 100%;
	}

	img {
		width: 250px !important;
		margin: 0 auto;
		padding: 10px;
	}

	h3 {
		text-transform: uppercase;
		color: var(--white);
		font-size: 0.8rem;
	}

	h4 {
		color: var(--light-grey);
		font-size: 1.5rem;
		margin: 0;
	}

	p {
		color: var(--white);
	}

	button {
		background-color: var(--accent-two);
		max-width: fit-content;
		margin: 10px auto;
	}
</style>
